import { createAction } from "@ngrx/store";
import { AUTH_V_UserRolePermission, Page } from "../../api/auth/model/user-profile.response";

export const saveLoggedInUserInfo = createAction(
    "[User] Save logged in user information",
    (
        email: string,
        first_name: string,
        last_name: string,
        comCode: string,
        pages: Page[],
        permissions: AUTH_V_UserRolePermission[]
    ) => ({ email, first_name, last_name, comCode, pages, permissions }),
);