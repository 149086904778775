import { Injectable } from "@angular/core";
import { Observable, map, of, switchMap, tap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { APIUrlToUse } from "../../enums/api-url-to-use.enum";
import { BaseService, ServiceName } from "../../services/base-service";
import { APIResponse } from "../common/models/api-response.model";
import { Page, UserProfielResponseDto } from "./model/user-profile.response";
import { Store } from "@ngrx/store";
import { saveLoggedInUserInfo } from "../../store/actions/user.actions";
import { selectAllUserState } from "../../store/selectors/user.selector";
import moment from "moment";


@Injectable({
    providedIn: "root",
})
export class AuthService extends BaseService {
    constructor(
        private http: HttpClient,
        private store: Store
    ) {
        super(APIUrlToUse.deployed, "http://localhost:3001", ServiceName.master);
    }

    private readonly url = {
        getUserProfile: "/auth/userprofile",
    };

    private get getUserProfileUrl(): string {
        return this.prepareUrl(this.url.getUserProfile);
    }

    getUserProfile() {
        return this.http.get<APIResponse<UserProfielResponseDto>>(this.getUserProfileUrl).pipe(
            tap((res) => {
                this.store.dispatch(saveLoggedInUserInfo(res.data.email, res.data.first_name, res.data.last_name, res.data.com_code, res.data.pages, res.data.permissions))
            })
        );
    }

    getAvailablePageIdsOfUser(): Observable<Page[]> {
        return this.store.select(selectAllUserState).pipe(
            switchMap((value) => {
                const now = moment();
                const expiry = value.expiry;
                if (expiry == null) {
                    return this.getUserProfile().pipe(map((i) => i.data.pages));
                }

                const minDiff = now.diff(expiry, "minutes");
                if (minDiff >= 5) {
                    return this.getUserProfile().pipe(map((i) => i.data.pages));
                }

                return of(value.pages);
            })
        );
    }
}
